$(function () {
	var $mobileToggle = $(".mobile-toggle");
	var $categoryFilter = $(".category-filter");
	var $lessonContainer = $(".lesson-container");
	var $navItems = $(".nav-items");
	var $updateDocumentSortButton = $(".update-document-sort-button");
	var $sortedDocument = $(".sorted-document");

	var $updateSortButton = $(".update-sort-button");
	var $sortedActivity = $(".sorted-activity");
	var $sortedActivityGroup = $(".sorted-activity-group");


	$updateDocumentSortButton.on('click', function () {
		console.log('udpating document order');
		var documentArray = [];
		var sortArray = [];
		$sortedDocument.each(function () {
			console.log($(this).data("id") + ":" + $(this).find(":selected").text());
			documentArray.push($(this).data("id"));
			sortArray.push($(this).find(":selected").text());
		})

		$.ajax({
      url: "/update_sort_order",
      type: "post",
      dataType: "json",
      data: {
        documents: documentArray,
        sort_order: sortArray
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
        console.log(response);
        console.log("reload");
        
      },
      error: function(response) {
        console.log(response);
      }
    });
    window.location.reload(true);
		
	})


	$updateSortButton.on('click', function () {
		var activityArray = [];
		var activityGroupArray = [];
		var sortArray = [];
		var sortGroupArray = [];

		$sortedActivity.each(function () {
			console.log($(this).data("id") + ":" + $(this).find(":selected").text());
			activityArray.push($(this).data("id"));
			sortArray.push($(this).find(":selected").text());
		})
		$sortedActivityGroup.each(function () {
			console.log($(this).data("id") + ":" + $(this).find(":selected").text());
			activityGroupArray.push($(this).data("id"));
			sortGroupArray.push($(this).find(":selected").text());
		})

		console.log(activityArray);
		console.log(activityGroupArray);
		console.log(sortArray);
		console.log(sortGroupArray);

		$.ajax({
      url: "/update_sort_order",
      type: "post",
      dataType: "json",
      data: {
        activities: activityArray,
        sort_order: sortArray,
        activities_for_groups: activityGroupArray,
        group_order: sortGroupArray
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
        console.log(response);
        console.log("reload");
        
      },
      error: function(response) {
        console.log(response);
      }
    });
    // window.location.reload(true);
		
	})

	$mobileToggle.on('click', function () {
		console.log("toggle items");
		$navItems.toggle();
	})

	$categoryFilter.on('click', function () {
		console.log("filter button clicked");
		console.log($(this).data("category"));
		filterLessons($(this).data("category"));
	})

	function filterLessons(tag) {
		$.ajax({
      url: "/past_lessons",
      type: "get",
      dataType: "json",
      data: {
        tag: tag
      },
      // headers: {"X-CSRF-TOKEN": $token},
      success: function(response) {
        console.log(response);
        renderFilteredLessons(response);
      },
      error: function(response) {
        console.log(response);
      }
    });	
	}

	function renderFilteredLessons(data) {
		$lessonContainer.empty();
		$.each(data, function (i, lesson) {
			var $outermostDiv = $("<div></div");
			$outermostDiv.addClass("lesson-box w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 p-4")
			$lessonContainer.append($outermostDiv);
			
			var $outerDiv = $("<div></div");
			$outerDiv.addClass("w-full border-2 rounded bg-gray-100 newblueborder p-8");
			$outermostDiv.append($outerDiv);
			var $header = $("<h1></h1>");
			$header.addClass("w-full text-xl font-bold");
			$header.text(`Lesson ${lesson.lesson_number}: ${lesson.lesson_name}`)
			var $headerLink = $("<a></a>");
			$headerLink.attr("href", `/lessons/${lesson.id}`);
			$header.append($headerLink);
			$outerDiv.append($header);
			var $videoDiv = $("<div></div");
			var $videoContainer = $("<div></div");
			$videoDiv.addClass("sm:p-4 md:p-8 lg:p-8 xl:p-8 text-center w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3 mx-auto");
			$videoContainer.addClass("video-container mx-auto");
			var $iframe = $(`<iframe src=${lesson.video_url} %> width="320" height="180"
				frameborder=	"	0" allowfullscreen="allowfullscreen" class="mx-auto">	</iframe>`);
			$iframe.addClass("mx-auto");
			$videoContainer.append($iframe);
			$videoDiv.append($videoContainer);
			$outerDiv.append($videoDiv);
			var $overviewDiv = $("<div></div>");
			$overviewDiv.addClass("w-full");
			var $trixDiv = $("<div></div>");
			$trixDiv.addClass("trix-content h-40 overflow-scroll");
			$trixDiv.append(`${lesson.overview}`);
			$overviewDiv.append($trixDiv);
			var $newForm = $("<form></form");
			$newForm.attr("method", "get");
    	$newForm.attr("action", `/lessons/${lesson.id}`);
    	$newForm.addClass("w-full text-center mx-auto");
    	var $newSubmit = $("<input></input>");
    	$newSubmit.addClass("rounded newbluebg px-8 py-4 text-xl text-center mt-8 text-white cursor-pointer mx-auto");
    	$newSubmit.attr("type", "submit");
    	$newSubmit.attr("value", "View Full Lesson");
    	$newForm.append($newSubmit);
    	$overviewDiv.append($newForm);
    	$outerDiv.append($overviewDiv);
		})	
	}
	
})
